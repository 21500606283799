<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import PDialog from 'primevue/dialog'
import { useActiveUserStore } from '../stores/ActiveUserStore'
import { multiPropFilter } from '../utils/arrayFilters'

const {
  showMenu
} = defineProps<{
  showMenu: boolean
}>()

const emit = defineEmits(['toggleMenu'])

const activeUserStore = useActiveUserStore()
const router = useRouter()
const query = ref('')

interface MenuItem {
  label: string
  desc?: string | null
  icon?: string | null
  command: any
  shortcut?: string | null
}

const baseMenuItems: Array<MenuItem> = [
  {
    label: 'Dashboard',
    desc: '',
    icon: 'far fa-square-poll-vertical',
    command: () => router.push('/'),
    shortcut: 'F3'
  },
  {
    label: 'Users',
    desc: 'Individual users',
    icon: 'fad fa-user',
    command: () => router.push('/users'),
    shortcut: 'F4'
  },
  {
    label: 'Accounts',
    desc: 'Customer Accounts',
    icon: 'fad fa-building',
    command: () => router.push('/accounts'),
    shortcut: null
  },
  {
    label: 'Addresses',
    desc: 'Directory of addresses',
    icon: 'fad fa-map',
    command: () => router.push('/addresses'),
    shortcut: null
  },
  {
    label: 'Items',
    desc: 'List of configured, non-configured, component, and non-inventory items',
    icon: 'fad fa-gauge-high',
    command: () => router.push('/items'),
    shortcut: 'F9'
  },
  {
    label: 'Configurators',
    desc: 'Configure a new item from a specific model',
    icon: 'fad fa-gauge-high',
    command: () => router.push('/configurators'),
    shortcut: null
  },
  {
    label: 'Base Models',
    desc: 'Top level models that configurators belong to',
    icon: 'fad fa-gauge-high',
    command: () => router.push('/base-models'),
    shortcut: null
  },
  {
    label: 'Quotes',
    desc: '',
    icon: 'fad fa-file-invoice',
    command: () => router.push('/quotes'),
    shortcut: 'F10'
  },
  {
    label: 'Quotes Follow Up',
    desc: 'Follow up module for quotes',
    icon: 'far fa-paper-plane',
    command: () => router.push('/quotes-follow-up'),
    shortcut: null
  },
  {
    label: 'Quotes Follow Up Report',
    desc: 'Report for Quotes Follow up',
    icon: 'far fa-chart-mixed',
    command: () => router.push('/quotes-follow-up-report'),
    shortcut: null
  },
  {
    label: 'Price Sheets',
    desc: 'Vendor price sheets',
    icon: 'fad fa-file-lines',
    command: () => router.push('/resources/price-sheets'),
    shortcut: null
  },
  {
    label: 'Thermowell Selection',
    desc: 'Thermowell selection tool',
    icon: 'fad fa-file-lines',
    command: () => router.push('/resources/thermowells'),
    shortcut: null
  },
  {
    label: 'Help',
    desc: 'Documentation on DTP and Daughtridge Sales processes',
    icon: 'fas fa-circle-question',
    command: () => window.open('https://dascosales.notion.site/DASHBOARD-5e8c4d78dfad44fd97ec70585c3f49aa', '_blank'),
    shortcut: null
  }
]

const adminMenuItems: Array<MenuItem> = [
  {
    label: 'Permissions',
    desc: '',
    icon: 'fad fa-lock',
    command: () => router.push('/admin/permissions'),
    shortcut: null
  },
  {
    label: 'Price Changes',
    desc: 'Bulk Percent Increases, Trigger Scheduled Price Changes',
    icon: 'fad fa-dollar',
    command: () => router.push('/admin/price-changes'),
    shortcut: null
  },
  {
    label: 'Settings',
    desc: 'Vendors, Price Brackets, etc',
    icon: 'fad fa-cogs',
    command: () => router.push('/settings'),
    shortcut: null
  },
]

const financeAdminItems: Array<MenuItem> = [
  {
    label: 'Sales Tax Certificates Campaign',
    icon: 'fad fa-file-invoice',
    command: () => router.push('/finance/sales-tax-certificates'),
    shortcut: null
  }
]

const secondaryMenuItems: Array<MenuItem> = [
  {
    label: 'Loaded Pricing Params',
    desc: 'Loaded Pricing Params for your session',
    icon: 'fad fa-file-lines',
    command: () => router.push('/loaded-pricing-params'),
    shortcut: null
  }
]
const filteredMenuItems = computed(() => {
  let items = [...baseMenuItems]
  if (activeUserStore.isAdmin || activeUserStore.isDevTeam)
    items = items.concat(adminMenuItems)
  if (activeUserStore.isFinanceAdmin)
    items = items.concat(financeAdminItems)

  items = items.concat(secondaryMenuItems)

  items = multiPropFilter({
    itemsArr: items,
    propsArr: ['label', 'desc'],
    query: query.value
  })
  return items
})

const doCommand = (i: any) => {
  i.command()
  emit('toggleMenu', false)
}
</script>

<template>
  <section class="w-30rem border-1 surface-border border-round-lg">
    <div
      class="flex w-full align-items-center justify-content-between p-1 cursor-pointer"
      @click="emit('toggleMenu', true)"
    >
      <fa-icon
        icon="fas fa-search"
        class="text-500 m-2"
      />
      <span class="w-full p-button-text link">Search menus, shortcuts, contact and more...</span>
      <span class="border-1 p-1 surface-border border-round surface-100 mr-2 text-600 text-xs hidden md:block">F2</span>
    </div>
  </section>
  <p-dialog
    :visible="showMenu"
    append-to="body"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    class="bg-white"
    content-class="border-round-top p-0"
    dismissable-mask
    modal
    :show-header="false"
    :style="{ width: '50vw', height: '50vh' }"
    @hide="emit('toggleMenu', false)"
    @update:visible="emit('toggleMenu', false)"
  >
    <div class="flex w-full surface-section align-items-center justify-content-between p-1">
      <p-btn
        class="p-button-text p-button-sm px-2 py-1 mx-2"
        label="Clear"
        @click="query = ''"
      />
      <div class="p-input-icon-left w-full flex align-items-center">
        <fa-icon
          icon="fas fa-search"
          class="text-500"
        />
        <p-input-text
          id="command-menu-input"
          v-model="query"
          name="command-menu-input"
          class="w-full border-none shadow-none outline-none"
          placeholder="Search menus, shortcuts, contact and more..."
          type="text"
          autofocus
        />
      </div>
      <span class="border-1 p-1 surface-border border-round surface-100 mr-2 text-600 text-xs hidden md:block">F2</span>
    </div>
    <div class="border-y-1 surface-border p-2">
      <article
        v-for="(i, idx) in filteredMenuItems"
        :key="idx"
        class="flex w-full justify-content-between align-items-center mb-3 select-none cursor-pointer surface-border hover:surface-hover border-round-lg px-2 py-1 text-800 hover:text-primary"
        @click="doCommand(i)"
      >
        <div class="flex align-items-center">
          <span class="border-circle w-2rem h-2rem flex align-items-center justify-content-center surface-100">
            <!-- <i :class="i.icon" /> -->
            <fa-icon
              :icon="i.icon"
              class="fa-fw"
            />
          </span>
          <div class="ml-2">
            <p class="font-semibold text-sm mt-0 mb-1">
              {{ i.label }}
            </p>
            <p
              v-if="i.desc"
              class="font-normal text-xs text-600 mt-0 mb-0"
            >
              {{ i.desc }}
            </p>
          </div>
        </div>
        <span
          v-if="i.shortcut"
          class="hidden md:inline-block border-1 px-2 py-1 surface-border border-round surface-100 mr-2 text-600 text-xs"
        >{{ i.shortcut }}</span>
      </article>
    </div>
  </p-dialog>
</template>
