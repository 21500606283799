import type { CodeDesc } from '../interfaces/CodeDesc'

export enum Status {
  // Created but not fully set up
  Pending = 'Pending',
  // Set up and is currently active
  Active = 'Active',
  // Needs to be reviewed for any reason
  NeedsReview = 'Needs Review',
  // Entity has been blocked, we also have the prop `blocked` for this at the higher level
  Blocked = 'Blocked',
  // Entity has been archived
  Archived = 'Archived',
  // Not Started
  NotStarted = 'Not Started',
  Completed = 'Completed'
}

export const statusArray: Array<CodeDesc> = Object
  .entries(Status)
  .map((i: any) => ({ code: i[1], desc: i[1] }))

export const statusArrayFlat: Array<string> = Object
  .entries(Status)
  .map((i: any) => i[1])

export const statusUser = {
  // Created by company and not fully set up by the customer

  pending: {
    label: 'Pending User Setup',
    chipClass: 'bg-purple-100 text-purple-600',
    icon: 'fa fa-sync'
  },
  active: {
    label: 'Active',
    chipClass: 'bg-green-100 text-green-700',
    icon: 'fa fa-check'
  },
  // User needs to be reviewed for any reason
  needsReview: {
    label: 'Needs Review',
    chipClass: 'bg-yellow-100 text-brown-600',
    icon: 'fa fa-check'
  },
  // User has been archived
  archived: {
    label: 'Archived',
    chipClass: 'bg-brown-50 text-brown-700',
    icon: 'fa fa-archive'
  },
  // User has been blocked, we also have the prop `blocked` for this at the higher level
  blocked: {
    label: 'Blocked',
    chipClass: 'bg-red-50 text-red-700',
    icon: 'fa fa-times'
  },
  unknown: {
    label: 'Unknown',
    chipClass: 'bg-orange-100 text-orange-700',
    icon: 'fa fa-question'
  }
}

export const statusUserArray = Object.values(statusUser)
