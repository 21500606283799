interface Bracket {
  discount: number
  from: number
  qty: number
}

interface PricingBrackets {
  [key: string]: {
    MCE: {
      brackets: Bracket[]
    },
    MCE15: {
      brackets: Bracket[]
    }
  }
}

export function buildMcePriceBrackets(payload: any = {}) {
  const {
    costMultipliers
  } = payload

  try {
    const brackets: PricingBrackets = { }

    if (!costMultipliers || !Object.keys(costMultipliers)?.length)
      throw new Error('No multipliers')

    const pcpgKeys = Object.keys(costMultipliers)

    pcpgKeys.forEach((pcpg: string) => {
      if (brackets[pcpg])
        throw new Error(`Possible duplicate multiplier ${pcpg}`)
      brackets[pcpg] = {
        MCE: {
          brackets: [
            {
              discount: +(1 - costMultipliers[pcpg]).toFixed(3),
              from: 1,
              qty: 999999999
            }
          ]
        },
        MCE15: {
          brackets: [
            {
              // MCE 15% creates a sell price of 15% more than DascoSales cost
              // This number that is desired is by dividing 0.85, however all other brackets are calculated by multiplying
              // Using 17.6% to get the desired 15% increase approved by Bill L., VP FlowControl
              discount: costMultipliers[pcpg] === 1 ? 0 : +(1 - (costMultipliers[pcpg] * 1.176)).toFixed(3),
              from: 1,
              qty: 999999999
            }
          ]
        }
      }
    })

    return {
      success: true,
      data: brackets
    }
  }
  catch (error) {
    console.error(error)
    // What to return?
    return {
      success: false
    }
  }
}
