import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { pick } from 'lodash-es'
import { useSentry } from '../composables/sentry'
import PricingService from '../services/PricingService'
import { useCustomerClassStore } from './CustomerClassStore'

const s = useSentry()

export const usePricingParamStore = defineStore('PricingParamStore', () => {
  const {
    customerClass,
    defaultCustomerClass
  } = useCustomerClassStore()
  const noDiscountBracket = {
    brackets: [
      {
        discount: 0,
        from: 1,
        qty: 999999999
      }
    ]
  }

  const unknownBracket = {
    [defaultCustomerClass]: noDiscountBracket,
    UNKNOWN: noDiscountBracket,
    MCE: noDiscountBracket,
    MCE15: noDiscountBracket
  }

  const pricingBrackets = ref()
  const costMultipliers = ref()

  // Loading all pricing brackets to session for DTP users
  async function getPricingBracketFromDatabaseGetAll() {
    try {
      let res: any
      const sessionKey = 'dasco-pricing-brackets'
      const sessionPricingBracketValue = sessionStorage.getItem(sessionKey) || ''
      if (!sessionPricingBracketValue) {
        res = await PricingService.getPricingBracketFromDatabaseGetAll()
        if (res?.data) {
          useStorage(sessionKey, { lastFetched: Date.now(), data: res.data }, sessionStorage)
          pricingBrackets.value = {
            ...res.data,
            UNKNOWN: unknownBracket,
            // For Non-Configured Items
            NONCONFNONCONF: unknownBracket
          }
        }
        return {
          success: true
        }
      }

      const _sessionData = JSON.parse(sessionPricingBracketValue).data || {}
      pricingBrackets.value = {
        UNKNOWN: unknownBracket,
        NONCONFNONCONF: unknownBracket,
        ..._sessionData
      }
      return {
        success: true
      }
    }
    catch (error: any) {
      s.handleError(error)
      return {
        success: false
      }
    }
  }

  // Load all cost multipliers to session for DTP users
  async function getCostMultipliers() {
    try {
      let res: any
      const sessionKey = 'dasco-cost-multipliers'
      const sessionConstMultipliersValue = sessionStorage.getItem(sessionKey) || ''
      if (!sessionConstMultipliersValue) {
        res = await PricingService.getCostMultipliers()
        if (res?.data) {
          useStorage(sessionKey, { lastFetched: Date.now(), data: res.data }, sessionStorage)
          costMultipliers.value = res.data
        }
        return {
          success: true
        }
      }

      costMultipliers.value = JSON.parse(sessionConstMultipliersValue).data || {}
      return {
        success: true
      }
    }
    catch (error: any) {
      s.handleError(error)
      return {
        success: false
      }
    }
  }

  /**
   * Build MCE price brackets from cost multipliers
   * Is dependent on loading cost multipliers from server
   */
  async function getMcePriceBrackets() {
    if (!Object.keys(costMultipliers.value))
      getCostMultipliers()
    const res = PricingService.buildMcePriceBrackets({ costMultipliers: costMultipliers.value })
    if (!res.success || !res.data || !Object.keys(res.data)?.length)
      throw new Error('No MCE price brackets built')

    const keys = new Set([...Object.keys(res.data), ...Object.keys(pricingBrackets.value)])

    keys.forEach((i: string) => {
      if (!Object.hasOwn(pricingBrackets.value, i)) {
        pricingBrackets.value[i] = res.data[i]
      }
      else {
        pricingBrackets.value[i] = {
          ...pricingBrackets.value[i],
          ...res.data[i]
        }
      }
    })
  }

  const bracketsForDoc = computed(() => {
    return (payload: any) => {
      const {
        customerClass = defaultCustomerClass, // Default to 99
        pcpgKeys
      } = payload

      const customerClassBrackets: any = {
        UNKNOWN: {
          brackets: [
            {
              customerClass: defaultCustomerClass,
              discount: 0,
              from: 1,
              qty: 999999999
            }
          ]
        }
      }
      try {
        const _pcpgKeys = pcpgKeys.filter((i: string) => i !== 'UNKNOWN')
        const brackets = pick(pricingBrackets.value, _pcpgKeys)
        /**
         * Set unknown as no discount
         * This helps catch missing PC PG issues
         */

        _pcpgKeys.forEach((i: any) => {
          customerClassBrackets[i] = brackets?.[i]?.[customerClass] || brackets?.[i]?.[defaultCustomerClass]
        })
        return customerClassBrackets
      }
      catch (error: any) {
        s.handleError(error)
        return customerClassBrackets
      }
    }
  })

  return {
    bracketsForDoc,
    costMultipliers,
    customerClass,
    getCostMultipliers,
    getMcePriceBrackets,
    getPricingBracketFromDatabaseGetAll,
    pricingBrackets,
  }
})
